import { Task } from "src/models/task";
import { useState, useEffect } from "react";
import { useToast } from "src/components/ui/use-toast";
import {
  addTask,
  deleteTask,
  fetchTasks,
  updateTask,
} from "src/services/tasks";

export const useTasks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [loadingTask, setLoadingTask] = useState<string | null>(null);
  const [taskIdToDelete, setTaskIdToDelete] = useState<string | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    handleFetchTasks();
  }, []);

  const handleFetchTasks = async () => {
    setIsLoading(true);
    try {
      const tasks = await fetchTasks();
      setTasks(tasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditTask = (task: Task) => {
    setSelectedTask(task);
    setModalOpen(true);
  };

  const onFloatingButtonClick = () => {
    const newTask: Task = {
      id: "",
      title: "",
      priority: "Low",
      status: "Pending",
      dueDate: new Date(),
    };
    setSelectedTask(newTask);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setSelectedTask(null);
    setModalOpen(false);
  };

  const handleDeleteTask = async () => {
    const taskId = taskIdToDelete || selectedTask?.id;
    if (!taskId) return;
    setLoadingTask(taskId);
    await deleteTask(taskId);
    setLoadingTask(null);
    toast({
      title: "Task has been deleted",
      description:
        tasks.find((task) => task.id === taskId)?.title || selectedTask?.title,
    });
    handleFetchTasks();
    setSelectedTask(null);
    setModalOpen(false);
  };

  const handleCompleteTask = async (e: React.FormEvent, task: Task) => {
    e.preventDefault();
    e.stopPropagation();
    setLoadingTask(task.id);
    await updateTask({
      ...task,
      status: "Completed",
    });
    setLoadingTask(null);
    toast({
      title: "Task has been completed",
      description: task.title,
    });
    handleFetchTasks();
  };

  const handleCreateTask = async () => {
    if (selectedTask) {
      setLoadingTask("new");
      await addTask(selectedTask);
      setLoadingTask(null);
      toast({
        title: "Task has been created",
        description: selectedTask?.title,
      });
      handleFetchTasks();
      onCloseModal();
    }
  };

  const handleUpdateTask = async () => {
    if (selectedTask) {
      setLoadingTask(selectedTask.id);
      await updateTask(selectedTask);
      setLoadingTask(null);
      toast({
        title: "Task has been updated",
        description: selectedTask.title,
      });
      handleFetchTasks();
      onCloseModal();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedTask?.id) {
      handleUpdateTask();
    } else {
      handleCreateTask();
    }
  };

  return {
    isLoading,
    tasks,
    handleEditTask,
    onCloseModal,
    isModalOpen,
    selectedTask,
    setSelectedTask,
    onFloatingButtonClick,
    loadingTask,
    handleCompleteTask,
    handleSubmit,
    handleDeleteTask,
    taskIdToDelete,
    setTaskIdToDelete,
  };
};

export default useTasks;

import React from "react";
import { Button, Spinner } from "src/components/ui";
import useTasks from "./useTasks";
import { Plus } from "lucide-react";
import TaskCard from "./TaskCard";
import TaskModal from "./TaskModal";

const Tasks: React.FC = () => {
  const {
    loadingTask,
    taskIdToDelete,
    isLoading,
    tasks,
    isModalOpen,
    selectedTask,
    onCloseModal,
    setSelectedTask,
    onFloatingButtonClick,
    handleSubmit,
    setTaskIdToDelete,
    handleEditTask,
    handleCompleteTask,
    handleDeleteTask,
  } = useTasks();

  return (
    <div className="container mx-auto p-4 w-full">
      <div className="space-y-4 w-full">
        {isLoading && <Spinner />}
        {tasks.map((task) => (
          <TaskCard
            key={task.id}
            task={task}
            taskIdToDelete={taskIdToDelete}
            setTaskIdToDelete={setTaskIdToDelete}
            onCardClick={handleEditTask}
            loadingTask={loadingTask}
            handleCompleteTask={handleCompleteTask}
            handleDeleteTask={handleDeleteTask}
          />
        ))}
      </div>

      <Button
        className="fixed bottom-4 right-4 p-3 rounded-ful shadow-lg"
        onClick={onFloatingButtonClick}
      >
        <Plus />
      </Button>

      <TaskModal
        onCloseModal={onCloseModal}
        isModalOpen={isModalOpen}
        selectedTask={selectedTask}
        setSelectedTask={setSelectedTask}
        loadingTask={loadingTask}
        handleSubmit={handleSubmit}
        handleDeleteTask={handleDeleteTask}
      />
    </div>
  );
};

export default Tasks;

import React from "react";
import { Button, Spinner } from "src/components/ui";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "src/components/ui/dialog";
import PriorityTaskSelector from "./PriorityTaskSelector";
import { Task } from "src/models/task";
import DueDateTaskPicker from "./DueDateTaskPicker";

interface TaskModalProps {
  onCloseModal: () => void;
  isModalOpen: boolean;
  selectedTask: Task | null;
  setSelectedTask: React.Dispatch<React.SetStateAction<Task | null>>;
  loadingTask: string | null;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  handleDeleteTask: () => void;
}

const TaskModal: React.FC<TaskModalProps> = ({
  onCloseModal,
  isModalOpen,
  selectedTask,
  setSelectedTask,
  loadingTask,
  handleSubmit,
  handleDeleteTask,
}) => {
  return (
    <Dialog open={isModalOpen} onOpenChange={onCloseModal}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Task</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 flex flex-col gap-4">
            <textarea
              value={selectedTask?.title || ""}
              onChange={(e) => {
                if (selectedTask) {
                  setSelectedTask({ ...selectedTask, title: e.target.value });
                }
              }}
              className="px-2 py-1 mb-2 w-full border rounded bg-background"
              rows={6}
            />
            <PriorityTaskSelector
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
            <DueDateTaskPicker
              selectedTask={selectedTask}
              setSelectedTask={setSelectedTask}
            />
          </div>
          <DialogFooter>
            <div className="flex flex-1 justify-between">
              {selectedTask?.id ? (
                <Button
                  type="button"
                  onClick={handleDeleteTask}
                  variant="destructive"
                >
                  Delete
                </Button>
              ) : (
                <div></div>
              )}
              <div className="flex gap-4">
                <Button type="button" onClick={onCloseModal} variant="outline">
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={!!loadingTask || selectedTask?.title.length === 0}
                >
                  {loadingTask ? <Spinner /> : "Save"}
                </Button>
              </div>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default TaskModal;

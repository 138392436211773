import { ThemeProvider } from "src/components/theme-provider";
import Tasks from "./pages/Tasks";
import { Toaster } from "./components/ui/toaster";

function App() {
  return (
    <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
      <Tasks />
      <Toaster />
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import { Task } from "src/models/task";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select";

interface PriorityTaskSelectorProps {
  selectedTask: Task | null;
  setSelectedTask: React.Dispatch<React.SetStateAction<Task | null>>;
}

const PriorityTaskSelector: React.FC<PriorityTaskSelectorProps> = ({
  selectedTask,
  setSelectedTask,
}) => {
  return (
    <Select
      onValueChange={(value) => {
        if (selectedTask) {
          setSelectedTask({ ...selectedTask, priority: value });
        }
      }}
      defaultValue={selectedTask?.priority || "Low"}
    >
      <SelectTrigger>
        <SelectValue placeholder="Select a Priority" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>Priority</SelectLabel>
          <SelectItem value="Low">Low</SelectItem>
          <SelectItem value="Medium">Medium</SelectItem>
          <SelectItem value="High">High</SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default PriorityTaskSelector;

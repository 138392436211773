import React from "react";
import { Button } from "src/components/ui";
import { format } from "date-fns";
import { CalendarIcon } from "lucide-react";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/components/ui/popover";
import { cn } from "src/lib/utils";
import { TimePicker } from "src/components/ui/time-picker";
import { Calendar } from "src/components/ui/calendar";
import { Task } from "src/models/task";

interface DueDateTaskPickerProps {
  selectedTask: Task | null;
  setSelectedTask: React.Dispatch<React.SetStateAction<Task | null>>;
}

const DueDateTaskPicker: React.FC<DueDateTaskPickerProps> = ({
  selectedTask,
  setSelectedTask,
}) => {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "justify-start text-left font-normal",
            !selectedTask?.dueDate && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {selectedTask?.dueDate ? (
            format(selectedTask?.dueDate, "PPP")
          ) : (
            <span>Pick a date</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={
            selectedTask?.dueDate ? new Date(selectedTask.dueDate) : new Date()
          }
          onSelect={(date) => {
            if (selectedTask) {
              setSelectedTask({ ...selectedTask, dueDate: date });
            }
          }}
          initialFocus
        />
        <TimePicker
          date={
            selectedTask?.dueDate ? new Date(selectedTask.dueDate) : new Date()
          }
          setDate={(date) => {
            if (selectedTask) {
              setSelectedTask({ ...selectedTask, dueDate: date });
            }
          }}
        />
      </PopoverContent>
    </Popover>
  );
};

export default DueDateTaskPicker;

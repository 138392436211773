import React from "react";
import { Button, Card, Spinner } from "src/components/ui";
import { CircleCheckBig, Trash } from "lucide-react";
import { Task } from "src/models/task";

interface TaskCardProps {
  task: Task;
  taskIdToDelete: string | null;
  setTaskIdToDelete: React.Dispatch<React.SetStateAction<string | null>>;
  onCardClick: (task: Task) => void;
  loadingTask: string | null;
  handleCompleteTask: (
    e: React.MouseEvent<HTMLButtonElement>,
    task: Task
  ) => void;
  handleDeleteTask: () => void;
}

const TaskCard: React.FC<TaskCardProps> = ({
  task,
  taskIdToDelete,
  setTaskIdToDelete,
  onCardClick,
  loadingTask,
  handleCompleteTask,
  handleDeleteTask,
}) => {
  return (
    <div className="w-full flex items-center cursor-pointer">
      <Card
        className="flex items-center justify-between p-4 rounded w-full"
        onClick={() => onCardClick(task)}
        onSwipeLeft={() => setTaskIdToDelete(task.id)}
        onSwipeRight={() => setTaskIdToDelete(null)}
      >
        <span
          className={`flex-1 ${
            task.status === "Completed" ? "line-through text-gray-500" : ""
          }`}
        >
          {task.title}
        </span>
        <div className="flex space-x-2">
          {task.status !== "Completed" && (
            <Button
              onClick={(e) => handleCompleteTask(e, task)}
              className={`px-2 py-1 rounded`}
              variant="ghost"
            >
              {loadingTask === task.id ? <Spinner /> : <CircleCheckBig />}
            </Button>
          )}
        </div>
      </Card>
      {task.id === taskIdToDelete && (
        <Button
          variant="destructive"
          className="h-full"
          onClick={handleDeleteTask}
        >
          <Trash />
        </Button>
      )}
    </div>
  );
};
export default TaskCard;

import { Task } from "src/models/task";
import axios from "axios";

const API_URL =
  "https://notion-task-manager-back.netlify.app/.netlify/functions/tasks";

export const fetchTasks = async () => {
  let fetchedTasks: Task[] = [];
  try {
    const response = await axios.get(API_URL, {
      headers: {
        Authorization: `Basic ${btoa("admin:123456")}`,
      },
    });
    fetchedTasks = response.data.results.map((task: any) => {
      return {
        id: task?.id,
        title: task?.properties?.Name?.title?.[0].plain_text,
        status: task?.properties?.Done?.checkbox ? "Completed" : "Pending",
        dueDate: task?.properties?.["Due Date"]?.date?.start,
        priority: task?.properties?.Priority?.select?.name,
      };
    });
  } catch (error) {
    console.error("Error fetching tasks:", error);
  } finally {
    return fetchedTasks;
  }
};

export const addTask = async (task: Task) => {
  if (task?.title?.trim()) {
    try {
      await axios.post(
        API_URL,
        {
          title: task?.title || "",
          dueDate: task?.dueDate
            ? new Date(task?.dueDate).toISOString()
            : new Date().toISOString(),
          priority: task?.priority || "",
        },
        {
          headers: {
            Authorization: `Basic ${btoa("admin:123456")}`,
          },
        }
      );
    } catch (error) {
      console.error("Error adding task:", error);
    }
  } else {
    alert("Please enter a task.");
  }
};

export const updateTask = async (updatedTask: Task) => {
  try {
    await axios.put(API_URL, updatedTask, {
      headers: {
        Authorization: `Basic ${btoa("admin:123456")}`,
      },
    });
  } catch (error) {
    console.error("Error updating task:", error);
  }
};

export const deleteTask = async (id: string) => {
  try {
    await axios.delete(API_URL, {
      data: { id },
      headers: {
        Authorization: `Basic ${btoa("admin:123456")}`,
      },
    });
  } catch (error) {
    console.error("Error deleting task:", error);
  }
};
